/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'unfetch/polyfill'; // Polyfill for `fetch` in IE11.
import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';
import SessionCheck from './src/components/session-check/session-check';
import LogRocket from 'logrocket';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <SessionCheck>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </SessionCheck>
  );
};

export const onClientEntry = () => {
  // initializes logrocket when site loads
  // Options are also configurable here
  try {
    if (process.env.GATSBY_LOGROCKET_APP_ID) {
      LogRocket.init(process.env.GATSBY_LOGROCKET_APP_ID);
    }
  } catch (error) {
    console.error(error);
  }
};
